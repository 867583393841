@import "~@blueprintjs/core";
@import "~mapbox-gl/dist/mapbox-gl.css";

.App {
  text-align: left;
}

.nav-dropdown-title {
  padding: 10px;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
  text-wrap: nowrap;
}

.map-container {
  margin: 10px 0;
  height: 620px;
  width: 100%;
}

.alert-timestamp {
  font-style: italic;
}

.osm-toggle-box {
  margin: 10px 0;
  text-align: center;
}

.time-controls {
  text-align: center;
}

.time-controls .d-flex > * {
  flex: 1;
  margin: 0 10px;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
.sensor-info {
  position: absolute;
  font-family: sans-serif;
  margin-top: 5px;
  margin-left: 5px;
  padding: 5px;
  width: 200px;
  border: 2px solid black;
  font-size: 14px;
  color: #222;
  background-color: #fff;
  border-radius: 3px;
  z-index: 100;
}

.row.cluster-button-row {
  margin: 20px 0;
}

.row.timestamp-row {
  margin: 10px 0;
}

.row.control-button-row {
  margin: 10px 0;
}
.row.control-button-row .btn {
  font-size: 30px;
  padding: 0;
}

.cluster-button-col > div {
  padding: 0;
  border-style: solid;
  border-width: 7px;
  border-radius: 5px;
}
.cluster-button-col button > * {
  font-size: 14px;
  white-space: nowrap;
}

.sensor-present > div {
  border-color: yellow;
}
.sensor-not-present > div {
  border-color: white;
}
.control-button-row > .control-button-slider {
  padding: 10px 0 0 0;
}

.date-picker-row.row{
  margin-bottom: 5px;
}

.top-selector-row.row {
  margin-top: 5px;
  margin-bottom:  5px;
}

.top-selector-row.row .btn {
  white-space: nowrap;
}

@media (min-width: 768px) {
  .date-picker-row.row .bp4-datepicker {
    justify-content: flex-end;
  }
}


.detection {
  font-size: 16px;
}

.detection > div > div {
  text-wrap: wrap;
}

.latency {
  font-size: 12px;
  color: gray;
  display: flex;
}

.latency > div {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px;
}

.csv-download {
  margin: 10px 0;
  text-align: right;
}

.metrics-menu {
  margin: 10px 0 20px 0;
}

.chart-container {
  height: 80vh;
}

.spacer {
  flex: 1;
}

.metrics-button-bar > button {
  margin: 0 6px 6px 0;
}

.full-width-button {
  width: 100%;
  white-space: nowrap;
}

.date-picker-row > div {
  padding-right: 0;
}

.log-filter-bar {
  margin: 0 4px;
}

.status-button-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.status-button-bar > button {
  margin: 0 10px;
}

.report-buttons > .dropdown {
  margin: 0 10px 5px 0;
}

.zone-box {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -16px;
}

.zone-box .zone-label {
}

.zone-box .org-label {
  font-size: 10px;
}

.rssi-indicator-unknown {
  color: lightgray;
  opacity: 0.5;
}

.rssi-indicator-0 {
  color: red;
}
.rssi-indicator-1 {
  color: darkorange;
}
.rssi-indicator-2 {
  color: orange;
}

.rssi-indicator-4, .rssi-indicator-3 {
  color: green
}


.battery-indicator-unknown {
  color: lightgray;
  opacity: 0.5;
}

.battery-indicator-0 {
  color: red;
}

.battery-indicator-1 {
  color: darkorange;
}

.battery-indicator-2 {
  color: green
}

.add-zone-form {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.add-zone-form > div {
  margin-bottom: 2em;
  flex-grow: 1;
}

.box-disabled {
  opacity: 0.3;
}

.full-height-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  min-height: 95vh;
}

.sensor-rssi-indicator {
  font-size: 3rem;
}

.sensor-rssi-indicator .arrow {
  font-size: 2rem;
}

.sensor-battery-indicator {
  font-size: 2rem;
}

.sensor-battery-indicator .arrow {
  font-size: 2rem;
}

.setup-row-text {
  font-size: x-large;
  font-weight: 500;
}